import { Divider, Flex, Navbar, ScrollArea, Text } from '@mantine/core'
import { SiReact, SiTypescript, SiVercel } from 'react-icons/si'
import { Link } from 'react-router-dom'

import config from '../config.json'
import ColorSchemeToggle from './ColorSchemeToggle'
import NavbarLinks from './NavbarLinksComponent'

export default function NavbarComponent() {
  return (
    <>
      <Navbar.Section>
        <Flex align="center" justify="space-between">
          <Link to="/" style={{ color: 'inherit', textDecoration: 'none' }}>
            <Flex align="center">
              <Text
                size="xl"
                weight={500}
                lineClamp={1}
                sx={(theme) => ({ paddingLeft: theme.spacing.xs })}
              >
                {config.name}
              </Text>
            </Flex>
          </Link>
          <ColorSchemeToggle />
        </Flex>
      </Navbar.Section>
      <Divider my="xs" />
      <Navbar.Section grow component={ScrollArea}>
        <NavbarLinks />
      </Navbar.Section>
      <Divider my="xs" />
      <Navbar.Section>
        <Text align="center" size="xs">
          <Link
            to="https://react.dev/"
            target="_blank"
            style={{ color: 'inherit', textDecoration: 'none' }}
          >
            <SiReact size="1rem" />
          </Link>{' '}
          <Link
            to="https://www.typescriptlang.org/"
            target="_blank"
            style={{ color: 'inherit', textDecoration: 'none' }}
          >
            <SiTypescript size="1rem" />
          </Link>{' '}
          <Link
            to="https://vercel.com/"
            target="_blank"
            style={{ color: 'inherit', textDecoration: 'none' }}
          >
            <SiVercel size="1rem" />
          </Link>
          <br />© 2017 - {new Date(Date.now()).getFullYear()} Hans362
          <br />
          All rights reserved.
        </Text>
      </Navbar.Section>
    </>
  )
}
