import { Button, Container, Flex } from '@mantine/core'
import { FiPlus } from 'react-icons/fi'
import { Link } from 'react-router-dom'

import FriendsComponent from '../components/FriendsComponent'

export default function FriendsPage() {
  return (
    <>
      <Flex align="baseline" justify="space-between">
        <h2>友链</h2>
        <Link to="https://github.com/hans362/Friends/" target="_blank">
          <Button variant="default" leftIcon={<FiPlus size="1rem" />}>
            添加友链
          </Button>
        </Link>
      </Flex>
      <Container pb="xl">
        <FriendsComponent />
      </Container>
    </>
  )
}
