import { Center, Container, Title } from '@mantine/core'

export default function ErrorPage() {
  return (
    <>
      <Container pb="xl">
        <Center mx="auto">
          <Title order={1}>404 Not Found</Title>
        </Center>
      </Container>
    </>
  )
}
