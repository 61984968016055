import {
  AppShell,
  Burger,
  Container,
  Header,
  Navbar,
  useMantineTheme,
} from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import NavbarComponent from './components/NavbarComponent'

export default function Layout(props: { page: JSX.Element }) {
  const theme = useMantineTheme()
  const [opened, setOpened] = useState(false)

  const isSm = useMediaQuery('(max-width: 768px)')

  const location = useLocation().pathname
  useEffect(() => {
    setOpened(false)
  }, [location])

  return (
    <AppShell
      padding="md"
      navbarOffsetBreakpoint="sm"
      navbar={
        <Navbar
          p="xs"
          sx={(theme) => ({
            backgroundColor:
              theme.colorScheme === 'dark'
                ? theme.colors.dark[8]
                : theme.colors.gray[0],
          })}
          hiddenBreakpoint="sm"
          hidden={!opened}
          width={{ sm: 200, lg: 300 }}
        >
          <NavbarComponent />
        </Navbar>
      }
      header={
        isSm ? (
          <Header height={{ base: 50, md: 70 }} p="md">
            <div
              style={{ display: 'flex', alignItems: 'center', height: '100%' }}
            >
              <Burger
                opened={opened}
                onClick={() => setOpened((o) => !o)}
                size="sm"
                color={theme.colors.gray[6]}
                mr="xl"
              />
            </div>
          </Header>
        ) : (
          <></>
        )
      }
      styles={(theme) => ({
        main: {
          backgroundColor:
            theme.colorScheme === 'dark'
              ? theme.colors.black
              : theme.colors.white,
        },
      })}
    >
      <Container size="xl">{props.page}</Container>
    </AppShell>
  )
}
