import {
  ColorScheme,
  ColorSchemeProvider,
  MantineProvider,
} from '@mantine/core'
import { useColorScheme, useLocalStorage } from '@mantine/hooks'
import { useEffect } from 'react'

import Layout from './Layout'

export default function App(props: { page: JSX.Element }) {
  const preferredColorScheme = useColorScheme()
  const [autoColorScheme] = useLocalStorage<true | 'light' | 'dark'>({
    key: 'mantine-auto-color-scheme',
    defaultValue: true,
    getInitialValueInEffect: true,
  })
  const [colorScheme, setColorScheme] = useLocalStorage<ColorScheme>({
    key: 'mantine-color-scheme',
    defaultValue: preferredColorScheme,
    getInitialValueInEffect: true,
  })
  useEffect(() => {
    if (autoColorScheme === true) setColorScheme(preferredColorScheme)
    else setColorScheme(autoColorScheme)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoColorScheme, preferredColorScheme])
  const toggleColorScheme = (value?: ColorScheme) =>
    setColorScheme(value || (colorScheme === 'dark' ? 'light' : 'dark'))

  return (
    <ColorSchemeProvider
      colorScheme={colorScheme}
      toggleColorScheme={toggleColorScheme}
    >
      <MantineProvider
        theme={{ colorScheme }}
        withGlobalStyles
        withNormalizeCSS
      >
        <Layout page={props.page} />
      </MantineProvider>
    </ColorSchemeProvider>
  )
}
