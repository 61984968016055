import { Card, Flex, Grid, Skeleton, Text } from '@mantine/core'
import { Link } from 'react-router-dom'
import useSWR from 'swr'

import config from '../config.json'

const Parser = require('rss-parser/dist/rss-parser.min.js')

interface PostProps {
  title: string
  link: string
  date: string
  author: string
}

function PostComponent({ title, link, date, author }: PostProps) {
  return (
    <Link
      to={link}
      target="_blank"
      style={{ color: 'inherit', textDecoration: 'none' }}
    >
      <Card withBorder shadow="sm" radius="md">
        <Card.Section withBorder inheritPadding py="xs">
          <Text weight={700} size="lg" lineClamp={1}>
            {title}
          </Text>
        </Card.Section>
        <Card.Section inheritPadding mt="sm">
          <Text size="sm" lineClamp={1}>
            {link}
          </Text>
        </Card.Section>
        <Card.Section inheritPadding pb="sm">
          <Text size="sm" color="grey" lineClamp={1}>
            {new Date(date).toLocaleDateString()} · {author}
          </Text>
        </Card.Section>
      </Card>
    </Link>
  )
}

function PostLoadingComponent() {
  return (
    <Card withBorder shadow="sm" radius="md">
      <Card.Section withBorder inheritPadding py="xs">
        <Skeleton height={28} radius="xs" width="40%" />
      </Card.Section>
      <Card.Section inheritPadding mt="sm">
        <Skeleton height={16} radius="xs" width="60%" />
      </Card.Section>
      <Card.Section inheritPadding pb="sm">
        <Skeleton height={16} mt={10} radius="xs" width="20%" />
      </Card.Section>
    </Card>
  )
}

export default function PostsComponent() {
  const parser = new Parser()
  const fetcher = (url: string) =>
    parser.parseURL(url).then((res: any) => res.items.slice(0, 8))
  const { data, error, isLoading } = useSWR(config.blog_rss, fetcher)
  if (isLoading || error) {
    const loading = []
    for (let i = 0; i < 10; i++) {
      loading.push(<PostLoadingComponent />)
    }
    return (
      <Flex direction={'column'} gap={'xl'}>
        {loading}
      </Flex>
    )
  }
  const resp = data.map((post: any) => {
    return (
      <Grid.Col>
        <PostComponent
          title={post.title}
          link={post.link}
          date={post.pubDate}
          author="hans362"
        />
      </Grid.Col>
    )
  })
  return <Grid>{resp}</Grid>
}
