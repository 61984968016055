import {
  Avatar,
  Button,
  Container,
  Flex,
  Grid,
  Space,
  Title,
  useMantineColorScheme,
} from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { BiSolidBook, BiSolidFlask } from 'react-icons/bi'
import { Link } from 'react-router-dom'

import BadgeComponent from '../components/BadgeComponent'

export default function HomePage() {
  const titleColor =
    useMantineColorScheme().colorScheme === 'dark' ? 'white' : 'black'
  const isSm = useMediaQuery('(max-width: 768px)')
  return (
    <Container pb="xl">
      <Space mih="64px" />
      <Grid>
        <Grid.Col span="auto"></Grid.Col>
        <Grid.Col md={7} sm={10}>
          {isSm ? (
            <>
              <Avatar
                src="avatar.jpg"
                size="xl"
                radius="xl"
                alt="Hans362"
                sx={(theme) => ({
                  border: '0.125rem solid',
                  borderColor:
                    titleColor === 'white'
                      ? theme.colors.yellow[1]
                      : theme.colors.blue[9],
                })}
              />
              <Space h="md" />
              <Title
                size="2.8rem"
                color={titleColor}
                style={{ fontWeight: 650 }}
              >
                Hello,{' '}
              </Title>
              <Title
                size="2.8rem"
                style={{ lineHeight: '80%', fontWeight: 650 }}
                color={titleColor}
              >
                I&apos;m{' '}
                <span
                  style={{
                    fontWeight: 700,
                    fontSize: '3.2rem',
                    letterSpacing: '-2px',
                  }}
                >
                  Hans362
                </span>
              </Title>
            </>
          ) : (
            <Flex align="flex-end" gap="xl">
              <Avatar
                src="avatar.jpg"
                size="xl"
                radius="xl"
                alt="Hans362"
                sx={(theme) => ({
                  border: '0.125rem solid',
                  borderColor:
                    titleColor === 'white'
                      ? theme.colors.yellow[1]
                      : theme.colors.blue[9],
                })}
              />
              <Flex direction="column">
                <Title
                  size="2.8rem"
                  color={titleColor}
                  style={{ fontWeight: 650 }}
                >
                  Hello,{' '}
                </Title>
                <Title
                  size="2.8rem"
                  style={{ lineHeight: '80%', fontWeight: 650 }}
                  color={titleColor}
                >
                  I&apos;m{' '}
                  <span
                    style={{
                      fontWeight: 700,
                      fontSize: '3.2rem',
                      letterSpacing: '-2px',
                    }}
                  >
                    Hans362
                  </span>
                </Title>
              </Flex>
            </Flex>
          )}
          <Space h="xl" />
          <BadgeComponent text="学生" />
          <BadgeComponent
            text="INFJ-A"
            color="teal"
            link="https://www.16personalities.com/ch/infj-%E4%BA%BA%E6%A0%BC"
          />
          <BadgeComponent text="CTFer@Ph0t1n1a" variant="filled" color="dark" />
          <BadgeComponent text="计算机科学爱好者" />
          <BadgeComponent
            text="菜鸡退役 OIer"
            color="orange"
            link="https://baike.baidu.com/item/oier/1532111"
          />
          <BadgeComponent text="社恐" />
          <BadgeComponent text="死宅" />
          <BadgeComponent
            text="Minecraft"
            color="lime"
            link="https://www.minecraft.net/"
          />
          <BadgeComponent
            text="Genshin Impact"
            color="yellow"
            link="https://genshin.hoyoverse.com/"
          />
          <Space h="xl" />
          <Flex gap="md">
            <Link to="https://blog.hans362.cn/" target="_blank">
              <Button variant="default" leftIcon={<BiSolidBook size="1rem" />}>
                博客
              </Button>
            </Link>
            <Link to="https://lab.hans362.cn/" target="_blank">
              <Button variant="default" leftIcon={<BiSolidFlask size="1rem" />}>
                实验室
              </Button>
            </Link>
          </Flex>
        </Grid.Col>
        <Grid.Col span="auto"></Grid.Col>
      </Grid>
    </Container>
  )
}
