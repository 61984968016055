import { Container, Divider, Text } from '@mantine/core'

import BadgeComponent from '../components/BadgeComponent'
import ProjectsComponent from '../components/ProjectsComponent'

export default function ProjectsPage() {
  return (
    <>
      <h2>项目</h2>
      <Container pb="md">
        <ProjectsComponent />
      </Container>
      <Divider my="xs" />
      <Text align="center" size="md" pt="xs" pb="xl">
        前往
        <BadgeComponent
          color="dark"
          text="GitHub"
          link="https://github.com/hans362/"
          variant="filled"
        />
        查看更多项目
      </Text>
    </>
  )
}
