import { Flex, Text, UnstyledButton } from '@mantine/core'
import {
  IconBook2,
  IconCode,
  IconFriends,
  IconHome,
  IconSocial,
  IconUser,
} from '@tabler/icons-react'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'

interface NavbarLinkProps {
  icon: React.ReactNode
  label: string
  link: string
}

const links = [
  { icon: <IconHome size="1.4rem" />, label: '主页', link: '/' },
  { icon: <IconBook2 size="1.4rem" />, label: '文章', link: '/posts' },
  { icon: <IconCode size="1.4rem" />, label: '项目', link: '/projects' },
  { icon: <IconSocial size="1.4rem" />, label: '社交', link: '/social' },
  { icon: <IconFriends size="1.4rem" />, label: '友链', link: '/friends' },
  { icon: <IconUser size="1.4rem" />, label: '关于', link: '/about' },
]

function NavbarLink({ icon, label, link }: NavbarLinkProps) {
  const location = useLocation().pathname
  return (
    <Link to={link} style={{ color: 'inherit', textDecoration: 'none' }}>
      <UnstyledButton
        sx={(theme) => ({
          display: 'block',
          width: '100%',
          paddingLeft: theme.spacing.xs,
          paddingTop: '0.5rem',
          paddingBottom: '0.5rem',
          marginTop: theme.spacing.xs,
          borderRadius: theme.radius.md,
          color:
            location === link || (location.startsWith(link) && link !== '/')
              ? theme.colorScheme === 'dark'
                ? theme.black
                : theme.white
              : theme.colorScheme === 'dark'
              ? theme.colors.dark[0]
              : theme.black,
          backgroundColor:
            location === link || (location.startsWith(link) && link !== '/')
              ? theme.colorScheme === 'dark'
                ? theme.colors.gray[4]
                : theme.colors.dark[4]
              : 'transparent',
          '&:hover': {
            backgroundColor:
              location === link
                ? theme.colorScheme === 'dark'
                  ? theme.colors.gray[4]
                  : theme.colors.dark[4]
                : theme.colorScheme === 'dark'
                ? theme.colors.dark[4]
                : theme.colors.gray[2],
          },
        })}
      >
        <Flex align="center" gap="xs">
          {icon}
          <Text size="md">{label}</Text>
        </Flex>
      </UnstyledButton>
    </Link>
  )
}

export default function NavbarLinks() {
  const resp = links.map((link) => <NavbarLink {...link} key={link.label} />)
  return <div>{resp}</div>
}
