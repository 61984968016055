import { Card, Flex, Grid, Text } from '@mantine/core'
import { FiMail } from 'react-icons/fi'
import {
  SiBilibili,
  SiGithub,
  SiSteam,
  SiTelegram,
  SiTwitter,
  SiZhihu,
} from 'react-icons/si'
import { Link } from 'react-router-dom'

interface SocialLinkProps {
  name: string
  link: string
  color: string
  icon: React.ReactNode
}

const links = [
  {
    name: 'GitHub',
    link: 'https://github.com/hans362',
    icon: <SiGithub size="2rem" />,
    color: '#24292f',
  },
  {
    name: 'Telegram',
    link: 'https://t.me/hans362',
    icon: <SiTelegram size="2rem" />,
    color: '#2CA5E0',
  },
  {
    name: 'Teleblog',
    link: 'https://t.me/Hans362sTeleblog',
    icon: <SiTelegram size="2rem" />,
    color: '#2CA5E0',
  },
  {
    name: 'Twitter',
    link: 'https://twitter.com/Milk_362',
    icon: <SiTwitter size="2rem" />,
    color: '#1da1f2',
  },
  {
    name: 'Steam',
    link: 'https://steamcommunity.com/id/hans362',
    icon: <SiSteam size="2rem" />,
    color: '#134375',
  },
  {
    name: '知乎',
    link: 'https://www.zhihu.com/people/hans-yu-65',
    icon: <SiZhihu size="2rem" />,
    color: '#0077ff',
  },
  {
    name: '哔哩哔哩',
    link: 'https://space.bilibili.com/66745436',
    icon: <SiBilibili size="2rem" />,
    color: '#fb7299',
  },
  {
    name: 'E-Mail',
    link: 'mailto:i@hans362.cn',
    icon: <FiMail size="2rem" />,
    color: '#8c8c8c',
  },
]

function SocialLinkComponent({ name, link, color, icon }: SocialLinkProps) {
  return (
    <Link
      to={link}
      target="_blank"
      style={{ color: 'inherit', textDecoration: 'none' }}
    >
      <Card withBorder shadow="sm" radius="md">
        <Card.Section withBorder inheritPadding py="xs">
          <Flex gap="xl" align="center" pt="xs" pb="xs">
            {icon}
            <Text weight={700} size="lg" lineClamp={1}>
              {name}
            </Text>
          </Flex>
        </Card.Section>
        <Card.Section
          inheritPadding
          pb="xs"
          style={{ backgroundColor: color }}
        ></Card.Section>
      </Card>
    </Link>
  )
}

export default function SocialComponent() {
  const resp = links.map((link: any) => {
    return (
      <Grid.Col sm={6} md={3}>
        <SocialLinkComponent
          name={link.name}
          link={link.link}
          color={link.color}
          icon={link.icon}
        />
      </Grid.Col>
    )
  })
  return <Grid>{resp}</Grid>
}
