import { Avatar, Card, Flex, Grid, Skeleton, Text } from '@mantine/core'
import axios from 'axios'
import React from 'react'
import { Link } from 'react-router-dom'
import useSWR from 'swr'

import config from '../config.json'

interface FriendProps {
  name: string
  link: string
  description: string
  avatar: string
}

function FriendComponent({ name, link, description, avatar }: FriendProps) {
  return (
    <Link
      to={link}
      target="_blank"
      style={{ color: 'inherit', textDecoration: 'none' }}
    >
      <Card withBorder shadow="sm" radius="md">
        <Card.Section withBorder inheritPadding py="xs">
          <Flex gap="xl" align="center">
            <Avatar src={avatar} size="lg" radius="xl" alt={name} />
            <Flex direction="column">
              <Text weight={700} size="lg" lineClamp={1}>
                {name}
              </Text>
              <Text size="sm" color="grey" lineClamp={1}>
                {description}
              </Text>
            </Flex>
          </Flex>
        </Card.Section>
        <Card.Section inheritPadding mt="xs" pb="xs">
          <Text size="sm" lineClamp={1}>
            {link}
          </Text>
        </Card.Section>
      </Card>
    </Link>
  )
}

function FriendLoadingComponent() {
  return (
    <Card withBorder shadow="sm" radius="md">
      <Card.Section withBorder inheritPadding py="xs">
        <Flex gap="xl" align="center">
          <Skeleton height={56} circle />
          <Skeleton height={56} width="50%" radius="md" />
        </Flex>
      </Card.Section>
      <Card.Section inheritPadding mt="xs" pb="xs">
        <Skeleton height={21} width="80%" />
      </Card.Section>
    </Card>
  )
}

function shuffle(a: []) {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[a[i], a[j]] = [a[j], a[i]]
  }
  return a
}

export default function FriendsComponent() {
  const fetcher = (url: string) =>
    axios.get(url).then((res: any) => shuffle(res.data.slice(1)))
  const { data, error, isLoading } = useSWR(config.friends_api, fetcher, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
  })
  if (isLoading || error) {
    const loading = []
    for (let i = 0; i < 12; i++) {
      loading.push(
        <Grid.Col sm={12} md={6}>
          <FriendLoadingComponent />
        </Grid.Col>
      )
    }
    return <Grid>{loading}</Grid>
  }
  const resp =
    data === undefined ? (
      <></>
    ) : (
      data.map((friend: any) => {
        return (
          <Grid.Col sm={12} md={6}>
            <FriendComponent
              name={friend.name}
              link={friend.url}
              avatar={friend.avatar}
              description={friend.desc}
            />
          </Grid.Col>
        )
      })
    )
  return <Grid>{resp}</Grid>
}
