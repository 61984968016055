import { Badge } from '@mantine/core'
import { Link } from 'react-router-dom'

interface BadgeComponentProps {
  color?: string
  text: string
  link?: string
  variant?: 'filled' | 'light' | 'outline' | 'dot'
}

export default function BadgeComponent({
  color = 'blue',
  text,
  link = '',
  variant = 'light',
}: BadgeComponentProps) {
  if (link === '')
    return (
      <>
        {' '}
        <Badge
          size="md"
          radius="sm"
          color={color}
          variant={variant}
          style={{
            textTransform: 'none',
            fontSize: '0.8rem',
            padding: '0.3rem',
          }}
        >
          {text}
        </Badge>{' '}
      </>
    )
  else
    return (
      <>
        {' '}
        <Link to={link} target="_blank">
          <Badge
            size="md"
            radius="sm"
            color={color}
            variant={variant}
            style={{
              textTransform: 'none',
              fontSize: '0.8rem',
              padding: '0.3rem',
            }}
          >
            {text}
          </Badge>
        </Link>{' '}
      </>
    )
}
