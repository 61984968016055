import { Container, TypographyStylesProvider } from '@mantine/core'
import { parse } from 'marked'

export default function AboutPage() {
  const about = `🚧 施工中...（咕咕咕）`
  return (
    <>
      <h2>关于</h2>
      <Container pb="xl">
        <TypographyStylesProvider>
          <div dangerouslySetInnerHTML={{ __html: parse(about) }} />
        </TypographyStylesProvider>
      </Container>
    </>
  )
}
