import { Container } from '@mantine/core'

import SocialComponent from '../components/SocialComponent'

export default function SocialPage() {
  return (
    <>
      <h2>社交</h2>
      <Container pb="xl">
        <SocialComponent />
      </Container>
    </>
  )
}
