import { Card, Flex, Grid, Text } from '@mantine/core'
import { FiMusic, FiRss } from 'react-icons/fi'
import { ImFilePicture } from 'react-icons/im'
import { SiApple, SiBilibili } from 'react-icons/si'
import { Link } from 'react-router-dom'

interface ProjectProps {
  name: string
  link: string
  slug: string
  icon: React.ReactNode
}

const projects = [
  {
    name: 'Bilibili-Bangumi-JS',
    link: 'https://github.com/hans362/Bilibili-Bangumi-JS',
    slug: 'hans362/Bilibili-Bangumi-JS',
    icon: <SiBilibili size="2rem" />,
  },
  {
    name: 'hexo-tag-bilibili-bangumi',
    link: 'https://github.com/hans362/hexo-tag-bilibili-bangumi',
    slug: 'hans362/hexo-tag-bilibili-bangumi',
    icon: <SiBilibili size="2rem" />,
  },
  {
    name: 'picgo-plugin-remove-exif',
    link: 'https://github.com/hans362/picgo-plugin-remove-exif',
    slug: 'hans362/picgo-plugin-remove-exif',
    icon: <ImFilePicture size="2rem" />,
  },
  {
    name: 'ThinkPad-X201s-Hackintosh',
    link: 'https://github.com/hans362/ThinkPad-X201s-Hackintosh',
    slug: 'hans362/ThinkPad-X201s-Hackintosh',
    icon: <SiApple size="2rem" />,
  },
  {
    name: 'RSSHub',
    link: 'https://github.com/diygod/RSSHub',
    slug: 'DIYgod/RSSHub',
    icon: <FiRss size="2rem" />,
  },
  {
    name: 'Meting',
    link: 'https://github.com/metowolf/Meting',
    slug: 'Metowolf/Meting',
    icon: <FiMusic size="2rem" />,
  },
]

function ProjectComponent({ name, link, slug, icon }: ProjectProps) {
  return (
    <Link
      to={link}
      target="_blank"
      style={{ color: 'inherit', textDecoration: 'none' }}
    >
      <Card withBorder shadow="sm" radius="md">
        <Card.Section withBorder inheritPadding py="xs">
          <Flex gap="xl" align="center">
            {icon}
            <Flex direction="column">
              <Text weight={700} size="lg" lineClamp={1}>
                {name}
              </Text>
              <Text size="sm" color="grey" lineClamp={1}>
                {slug}
              </Text>
            </Flex>
          </Flex>
        </Card.Section>
        <Card.Section inheritPadding mt="xs" pb="xs">
          <Text size="sm" lineClamp={1}>
            {link}
          </Text>
        </Card.Section>
      </Card>
    </Link>
  )
}

export default function ProjectsComponent() {
  const resp = projects.map((project: any) => {
    return (
      <Grid.Col sm={12} md={6}>
        <ProjectComponent
          name={project.name}
          link={project.link}
          slug={project.slug}
          icon={project.icon}
        />
      </Grid.Col>
    )
  })
  return <Grid>{resp}</Grid>
}
