import { ActionIcon, Menu, useMantineColorScheme } from '@mantine/core'
import { useLocalStorage } from '@mantine/hooks'
import {
  IconBrightness,
  IconDeviceDesktop,
  IconMoon,
  IconSun,
} from '@tabler/icons-react'

export default function ColorSchemeToggle() {
  const { colorScheme } = useMantineColorScheme()
  const [autoColorScheme, setAutoColorScheme] = useLocalStorage<
    true | 'light' | 'dark'
  >({
    key: 'mantine-auto-color-scheme',
    defaultValue: true,
    getInitialValueInEffect: true,
  })
  const dark = colorScheme === 'dark'

  return (
    <Menu shadow="sm">
      <Menu.Target>
        <ActionIcon color={dark ? 'yellow' : 'blue'}>
          {<IconBrightness size={26} />}
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item
          color={
            autoColorScheme === true ? (dark ? 'yellow' : 'blue') : undefined
          }
          icon={<IconDeviceDesktop size={16} />}
          onClick={() => setAutoColorScheme(true)}
        >
          跟随系统
        </Menu.Item>
        <Menu.Item
          color={
            autoColorScheme === 'light' ? (dark ? 'yellow' : 'blue') : undefined
          }
          icon={<IconSun size={16} />}
          onClick={() => {
            setAutoColorScheme('light')
          }}
        >
          总是浅色
        </Menu.Item>
        <Menu.Item
          color={
            autoColorScheme === 'dark' ? (dark ? 'yellow' : 'blue') : undefined
          }
          icon={<IconMoon size={16} />}
          onClick={() => {
            setAutoColorScheme('dark')
          }}
        >
          总是深色
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  )
}
