import React from 'react'
import ReactDOM from 'react-dom/client'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'

import App from './App'
import './index.css'
import AboutPage from './pages/AboutPage'
import ErrorPage from './pages/ErrorPage'
import FriendsPage from './pages/FriendsPage'
import HomePage from './pages/HomePage'
import PostsPage from './pages/PostsPage'
import ProjectsPage from './pages/ProjectsPage'
import SocialPage from './pages/SocialPage'
import reportWebVitals from './reportWebVitals'

const router = createBrowserRouter([
  {
    path: '/',
    element: <App page={<HomePage />} />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/posts',
    element: <App page={<PostsPage />} />,
  },
  {
    path: '/projects',
    element: <App page={<ProjectsPage />} />,
  },
  {
    path: '/social',
    element: <App page={<SocialPage />} />,
  },
  {
    path: '/friends',
    element: <App page={<FriendsPage />} />,
  },
  {
    path: '/about',
    element: <App page={<AboutPage />} />,
  },
])

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
