import { Button, Container, Divider, Flex, Text } from '@mantine/core'
import { FiRss } from 'react-icons/fi'
import { Link } from 'react-router-dom'

import BadgeComponent from '../components/BadgeComponent'
import PostsComponent from '../components/PostsComponent'
import config from '../config.json'

export default function PostsPage() {
  return (
    <>
      <Flex align="baseline" justify="space-between">
        <h2>文章</h2>
        <Link to={config.blog_rss} target="_blank">
          <Button variant="default" leftIcon={<FiRss size="1rem" />}>
            RSS 订阅
          </Button>
        </Link>
      </Flex>
      <Container pb="md">
        <PostsComponent />
      </Container>
      <Divider my="xs" />
      <Text align="center" size="md" pt="xs" pb="xl">
        前往
        <BadgeComponent
          color="blue"
          text="Hans362 ' s Blog"
          link="https://blog.hans362.cn/"
        />
        阅读更多文章
      </Text>
    </>
  )
}
